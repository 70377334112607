<template>
  <div class="">
    <div class="w-50 m-auto text-center">
      <div class="row">
        <div class="col text-left text-muted p-2 d-none d-sm-block">
          <span> الكود: </span>
        </div>
        <div class="col text-center text-sm-right">
          <h2>{{ settings.discountCode }}</h2>
        </div>
      </div>
    </div>
    <div class="w-50 m-auto text-center">
      <div class="row">
        <div class="col text-left text-muted p-2 d-none d-sm-block">
          <span>التاريخ: </span>
        </div>
        <div class="col text-center text-sm-right p-2">
          <span>{{ getExactDate(new Date()) }}</span>
        </div>
      </div>
    </div>
    <PanelList
        class="mt-5"
      :items="discounts"
      title="الخصومات"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleDiscountsSelection()"
      :canSelectAll="false"
    >
      <DiscountPanelItem
        v-for="(discount, i) in discounts"
        :key="i"
        :discount="discount"
      />
    </PanelList>

    <DiscountModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import DiscountPanelItem from "@/components/panel-items/discount";
import DiscountModal from '@/components/modals/discount'
import DiscountsMixin from '@/mixins/discounts'
import GeneralMixin from '@/mixins/general'

export default {
  mixins: [DiscountsMixin, GeneralMixin],

  components: {
    PanelList,
    DiscountPanelItem,
    DiscountModal
  },
};
</script>
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faCheckCircle,
    faExclamation,
    faBars,
    faUserCog,
    faFileSignature,
    faTags,
    faBell,
    faUsers,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faEdit,
    faTools
} from '@fortawesome/free-solid-svg-icons'

import {
    faUser
} from '@fortawesome/free-regular-svg-icons'

import {
    faInstagram,
    faYoutube,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faCheckCircle,
    faExclamation,
    faInstagram,
    faYoutube,
    faTwitter,
    faBars,
    faUser,
    faUserCog,
    faFileSignature,
    faTags,
    faBell,
    faUsers,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faEdit,
    faTools
)

Vue.component('fa', FontAwesomeIcon)
<template>
  <div class="container">

    <div class="panel mb-5">
      <div class="header">
        <span>بياناتي</span>
      </div>

      <div class="body p-4">
        <div class="text-center w-100 m-auto">
          <div class="form-group text-right">
            <label class="small">الاسم:</label>
            <input
              class="form-control"
              placeholder="الاسم"
              disabled
              v-model="user.name"
            />
            <div v-if="!user.name" class="small text-red">
              الإدخال مطلوب.
            </div>
          </div>

          <div class="form-group mt-3 text-right">
            <label class="small">رقم الجوال:</label>
            <input
              class="form-control"
              placeholder="رقم الجوال"
              type="text"
              disabled
              v-model="user.mobile"
            />
            <div v-if="!user.mobile" class="small text-red">
              الإدخال مطلوب.
            </div>
          </div>

          <!-- <div class="form-group mt-3 text-right">
            <label class="small">البريد الإلكتروني:</label>
            <input
              class="form-control"
              placeholder="البريد الإلكتروني"
              type="email"
              disabled
              v-model="user.email"
            />
            <div v-if="!user.email" class="small text-red">
              الإدخال مطلوب.
            </div>
          </div> -->

          <div class="form-group mt-3 text-right">
            <label class="small">عنوان العمل:</label>
            <textarea
              class="form-control"
              placeholder="عنوان العمل"
              disabled
              v-model="user.workAddress"
            ></textarea>
            <div v-if="!user.workAddress" class="small text-red">
              الإدخال مطلوب.
            </div>
          </div>

          <div class="form-group mt-3 text-right">
            <label class="small">رقم الهوية:</label>
            <input
              class="form-control"
              placeholder="رقم الهوية"
              disabled
              v-model="user.idNumber"
            />
            <div v-if="!user.idNumber" class="small text-red">الإدخال مطلوب.</div>
          </div>

          <div
            class="d-inline-block mt-3"
          >
            <div class="m-auto">
              <img
                ref="idCardPreview"
                class="id-card-preview m-auto"
                :src="getImage"
                alt=""
              />
            </div>
            <!-- <span class="text-muted small">تحديث صورة الهوية</span>
            <input
              ref="idCardImage"
              type="file"
              style="display: none"
              @change="updateImage"
              accept="image/*"
            /> -->
          </div>
        </div>

        <!-- <button class="btn btn-block btn-primary mt-5" @click="submit">
            <fa icon="save" />
            <span>حفظ</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
  mixins: [AuthMixin],

  methods: {
    // submit() {
    //   this.updateUserData().then((res) => {
    //     if (res.success) {
    //       // this.showSwal(res)
    //       if (this.$refs.idCardImage.files[0]) {
    //         this.saveUserImage(this.$refs.idCardImage.files[0])
    //       }
    //     }
    //   });
    // },

    // updateImage (e) {
    //   this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0])
    // }
  },

  computed: {
    getImage () {
      return this.user.image ? this.user.image.link : require('@/assets/images/image-placeholder.png')
    }
  }
}
</script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveMaintenance: 'maintenances/saveMaintenance',
            toggleMaintenancesSelection: 'maintenances/toggleMaintenancesSelection',
            toggleMaintenanceSelection: 'maintenances/toggleMaintenanceSelection',
            deleteSelectedMaintenances: 'maintenances/deleteSelectedMaintenances',
            deleteImage: 'maintenances/deleteImage',
        }),

        ...mapMutations({
            selectMaintenance: 'maintenances/SET_SELECTED_MAINTENANCE'
        })
    },

    computed: {
        ...mapGetters({
            maintenances: 'maintenances/maintenances',
            selectedMaintenance: 'maintenances/selectedMaintenance',
            selectedMaintenances: 'maintenances/selectedMaintenances'
        })
    }
}
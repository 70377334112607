<template>
  <li>
    <a href="#!" @click.prevent="showAttendantTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 panel-info text-center text-sm-right">
          <!-- <div
            @click.stop
            v-if="['Pending Payment', 'Awaiting Review'].includes(order.status)"
            class="d-inline"
          >
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="order.selected"
              :value="true"
              @change.native="toggleOrderSelection(order)"
            >
            </b-form-checkbox>
          </div> -->
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
            {{ attendant.name }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            <!-- {{ attendant.email }} -->
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            {{ attendant.mobile }}
        </div>
      </div>
    </a>

    
  </li>
</template>

<script>
import AttendantsMixin from '@/mixins/attendants'

export default {
  mixins: [AttendantsMixin],

  methods: {
    showAttendantTap () {
      this.selectAttendant(this.attendant)
      this.$bvModal.show('attendant-modal')
    }
  },

  props: ["attendant"],
};
</script>
import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        maintenances: [],
        selectedMaintenance: null
    },

    getters: {
        maintenances: state => state.maintenances,
        selectedMaintenance: state => state.selectedMaintenance,
        selectedMaintenances: state => {
            return state.maintenances.filter(m => m.selected)
        },
    },

    mutations: {
        SET_MAINTENANCES: (state, payload) => state.maintenances = payload,
        SET_SELECTED_MAINTENANCE: (state, payload) => state.selectedMaintenance = payload,
        SELECT_ALL: state => {
            state.maintenances.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.maintenances.forEach(m => m.selected = false)
        },
        SELECT_MAINTENANCE: (state, payload) => {
            state.maintenances[state.maintenances.indexOf(payload)].selected = true
        },

        DESELECT_MAINTENANCE: (state, payload) => {
            state.maintenances[state.maintenances.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchMaintenances({ commit }) {
            let { data } = await axios.get('user/maintenances')
            data.maintenances.forEach(m => m.selected = false)
            commit('SET_MAINTENANCES', data.maintenances)

        },

        async saveMaintenance({ commit, dispatch, getters, rootState }, payload) {
            let foundProperty = rootState.properties.properties.find(p => p.number == payload.property.number)
            if (!foundProperty) {
                return { success: false, message: 'لا يوجد عقار بهذا الرقم' }
            }
            payload.property_id = foundProperty.id

            let formData = new FormData()

            if (payload.images) {
                for (let i = 0; i < payload.images.length; i++) {
                    formData.append('images[' + i + ']', payload.images[i])
                }
            }

            payload.images = null
            payload.property = null

            for (const [key, value] of Object.entries(payload)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let url = getters.selectedMaintenance ? `user/maintenances/${getters.selectedMaintenance.id}` : `user/maintenances`
                // let { data } = await axios({
                //     method: getters.selectedMaintenance ? 'put' : 'post',
                //     url,
                //     data: payload
                // })

            let { data } = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchMaintenances')
                dispatch('properties/fetchProperties', {}, { root: true })
                let property = rootState.properties.properties.find(p => p.id == payload.property_id)
                commit('properties/SELECT_PROPERTY', property, { root: true })
                    // commit('properties/ADD_MAINTENANCE', data.maintenance, { root: true })
                    // rootState.properties.selectedProperty = data.property
            }

            return data
        },

        toggleMaintenancesSelection({ commit, getters, state }) {
            if (getters.selectedMaintenances.length < state.maintenances.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleMaintenanceSelection({ commit, state }, payload) {
            let currentState = state.maintenances[state.maintenances.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_MAINTENANCE', payload)
            } else {
                commit('DESELECT_MAINTENANCE', payload)
            }
        },

        async deleteSelectedMaintenances({ getters, dispatch }) {
            let ids = []
            getters.selectedMaintenances.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('user/maintenances', { params: { ids } })
            dispatch('fetchMaintenances')
            return data
        },

        async deleteImage(_, image) {
            console.log('in deleteImage method')
            let { data } = await axios.delete(`images/${image.id}`)

            return data
        },
    }
}
<template>
  <li>
    <a href="#!" @click.prevent="showNotificationTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 panel-info text-center text-sm-right">
          <!-- <div
            @click.stop
            v-if="['Pending Payment', 'Awaiting Review'].includes(order.status)"
            class="d-inline"
          >
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="order.selected"
              :value="true"
              @change.native="toggleOrderSelection(order)"
            >
            </b-form-checkbox>
          </div> -->
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
            {{ notification.body }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
            {{ getDate(notification.created_at) }}
        </div>
      </div>
    </a>

    
  </li>
</template>

<script>
import NotificationsMixin from '@/mixins/notifications'

export default {
  mixins: [NotificationsMixin],
  
  methods: {
    showNotificationTap () {
      this.selectNotification(this.notification)
      this.$bvModal.show('notification-modal')
    }
  },
  props: ["notification"],
};
</script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            toggleNotificationsSelection: 'notifications/toggleNotificationsSelection',
            toggleNotificationSelection: 'notifications/toggleNotificationSelection',
            deleteSelectedNotification: 'notifications/deleteSelectedNotification',
            clearNotificationsCounter: 'notifications/clearNotificationsCounter'
        }),

        ...mapMutations({
            selectNotification: 'notifications/SET_SELECTED_NOTIFICATION',
        })
    },

    computed: {
        ...mapGetters({
            notifications: 'notifications/notifications',
            selectedNotification: 'notifications/selectedNotification',
            selectedNotifications: 'notifications/selectedNotifications',
        })
    }
}
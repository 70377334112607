import axios from '@/axios'
import moment from 'moment-timezone'

export default {
    namespaced: true,

    state: {
        properties: [],
        selectedProperty: null
    },

    getters: {
        properties: state => state.properties,
        selectedProperty: state => state.selectedProperty
    },

    mutations: {
        SET_PROPERTIES: (state, payload) => state.properties = payload,
        SELECT_PROPERTY: (state, payload) => state.selectedProperty = payload,
        ADD_MAINTENANCE: (state, payload) => {
            let property = state.properties.find(p => p.id == state.selectedProperty.id)
            property.maintenances.push(payload)
        }
    },

    actions: {
        async fetchProperties({ commit }) {
            let { data } = await axios.get('properties')

            console.log(moment().local().toDate())
            let today = moment().local().toDate()
                // console.log(moment().tz('Asia/Riyadh'))

            data.properties.forEach(p => {
                if (p.agreement) {
                    for (let i = 0; i < 12; i++) {
                        if (moment(p.agreement['payment' + (i + 1)]).diff(today, 'days') <= 0) {
                            p.isDue = true
                            if (p.agreement['paid' + (i + 1)] > 0 && p.agreement['paid' + (i + 1)] < p.agreement.totalAmount) {
                                p.orange = true
                            } else if (p.agreement['paid' + (i + 1)] == 0) {
                                p.red = true
                            }
                        }
                    }
                }
            })

            commit('SET_PROPERTIES', data.properties)

        },
    }
}
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({

        }),

        ...mapMutations({
            selectDiscount: 'discounts/SELECT_DISCOUNT'
        })
    },

    computed: {
        ...mapGetters({
            discounts: 'discounts/discounts',
            selectedDiscount: 'discounts/selectedDiscount'
        })
    }
}
<template>
  <b-modal id="notification-modal">
    <template v-slot:modal-header="">
      <h5>عرض الإشعار</h5>
    </template>

    <template v-slot:default="">
        <div class="p-3">
            <div class="notification-content-wrapper">
                {{ selectedNotification.body }}
            </div>

            <div class="notification-attachments-wrapper mt-3">
                <div class="text-muted">المرفقات</div>
                <div>
                    <a v-for="(file, i) in selectedNotification.files" :key="i" download :href="file.link" class="mx-2" target="_blank">مرفق{{ i+1 }}</a>
                </div>
            </div>
        </div>
    </template>

    <template v-slot:modal-footer="">
      <!-- <button type="submit" class="btn btn-primary" @click="submit">حفظ</button> -->
      <a href="#" class="text-muted" @click="$bvModal.hide('notification-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import NotificationsMixin from '@/mixins/notifications'

export default {
  mixins: [NotificationsMixin],

}
</script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveAttendant: 'attendants/saveAttendant',
            saveAttendantImage: 'attendants/saveAttendantImage',
            toggleAttendantsSelection: 'attendants/toggleAttendantsSelection',
            toggleAttendantSelection: 'attendants/toggleAttendantSelection',
            deleteSelectedAttendants: 'attendants/deleteSelectedAttendants',
        }),

        ...mapMutations({
            selectAttendant: 'attendants/SET_SELECTED_ATTENDANT',
        })
    },

    computed: {
        ...mapGetters({
            attendants: 'attendants/attendants',
            selectedAttendant: 'attendants/selectedAttendant',
            selectedAttendants: 'attendants/selectedAttendants',
        })
    }
}